import React from "react";
import sucessDelete from "../utils/successDelete.svg";

const SuccessDelete = () => {
  return (
    <div className="popup">
      <img src={sucessDelete} alt="SucessDelete" />
    </div>
  );
};

export default SuccessDelete;
