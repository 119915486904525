import React from "react";
import footerI from "../utils/footer.svg";

const Footer = () => {
  return (
    <footer>
      <img src={footerI} alt="Footer" />
    </footer>
  );
};

export default Footer;
